import React from "react"
import { Link } from "gatsby"
import { ArrowRight } from "react-feather"
import { VscCheck } from "react-icons/vsc"
import { graphql } from "gatsby"
import { Layout } from "../components/Layout"
import { SEO } from "../components/SEO"
import { TestimonialCard } from "../components/TestimonialCard"
import { ProductSlider } from "../components/ProductSlider"
import { PostCard } from "../components/PostCard"
import { CTABlock } from "../components/CTABlock"
import { TextImage } from "../components/TextImage"
import { FadeInWrapper } from "../components/FadeInWrapper"
import { Carousel } from "../components/Carousel"
import lipsBefore from "../assets/images/lips-before.jpg"
import lipsAfter from "../assets/images/lips-after.jpg"
import tearTroughsAfter from "../assets/images/results-tear-troughs-after.jpg"
import tearTroughsBefore from "../assets/images/results-tear-troughs-before.jpg"
import profhiloBefore from "../assets/images/profhilo-before.jpg"
import profhiloAfter from "../assets/images/profhilo-after.jpg"
import jawlineBefore from "../assets/images/jawline-before.jpg"
import jawlineAfter from "../assets/images/jawline-after.jpg"
import mesoestetic from "../assets/images/brand-logo-mesoestetic.svg"
import neostrata from "../assets/images/brand-logo-neostrata.svg"
import profhilo from "../assets/images/brand-logo-profhilo.svg"
import teoxane from "../assets/images/brand-logo-teoxane.svg"
import zo from "../assets/images/brand-logo-zo.svg"
import test2 from "../assets/images/hero-2.jpg"
import cover1 from "../assets/images/cover-1.jpg"
import buttons from "../styles/buttons.module.css"
import helpers from "../styles/helpers.module.css"
import room from "../assets/images/room.png"
import yaldaNeedle from "../assets/images/yalda-needle.jpg"

const IndexPage = props => {
  const data = props.data.allFile.edges
  const reviews = []

  const blogData = props.data.allMarkdownRemark.edges
  const blogsDestructured = []

  blogData.map(blog => {
    return blogsDestructured.push({
      ...blog.node.frontmatter,
      ...blog.node.fields,
    })
  })

  data.map(review => {
    return reviews.push(review.node.childMarkdownRemark.frontmatter)
  })

  return (
    <Layout page="Home">
      <SEO title="Home" />
      <FadeInWrapper>
        <div className="lg:py-12 xl:pt-8 xl:pb-24">
          <div className="container px-0 py-8 mx-auto md:py-8 lg:py-0 lg:px-0 xl:py-0 xl:px-12">
            <div className="px-6 md:px-12 lg:px-6 xl:px-4">
              <div className="p-8 bg-gray-100 mainHeader lg:py-24 lg:px-16 xl:py-16">
                <div className="pt-8 text-center mainHeaderText sm:pt-16 lg:text-left lg:py-6 xl:py-16">
                  <p className="mb-2 text-lg font-bold tracking-wide text-lightAccent">
                    DR YALDA CLINICS
                  </p>
                  <h1 className="mb-6 text-4xl tracking-tight text-gray-900 md:leading-tight md:text-6xl sm:text-5xl md:mb-4 lg:mb-0 lg:leading-tight lg:text-5xl xl:text-6xl xl:mb-0">
                    A Medical Approach To Facial Aesthetics.
                  </h1>
                  <p className="mb-12 text-base text-gray-900 xl:text-lg lg:mb-12 lg:pr-8">
                    Our team of medically qualified professionals offer a range
                    of non-surgical cosmetic treatments across Manchester,
                    Nottingham and Leeds.
                  </p>
                  <div>
                    <Link
                      className={`${buttons.btnPrimary} block mb-2 lg:mr-2 lg:inline-block`}
                      to="/contact"
                    >
                      Get in touch
                    </Link>
                    <Link
                      className={`${buttons.btnSecondaryOutline} block mb-12 lg:mb-0 lg:inline-block`}
                      to="https://app.sellsio.co/online-bookings/38b43b72b261477ab2ca9ea2634ee240"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Book online
                    </Link>
                  </div>
                </div>
                <div className="mainHeaderImages01">
                  <img
                    src={room}
                    alt="Clinic room with a green plant"
                    className="shadow-sm"
                  />
                </div>
                <div className="mainHeaderImages02">
                  <img
                    src={test2}
                    alt="Dr Yalda smiling, wearing a blue shirt"
                    className="shadow-md headerImage02"
                  />
                </div>
                <div className="mainHeaderImages02Mobile">
                  <img
                    src={test2}
                    alt="Dr Yalda smiling, wearing a blue shirt"
                    className="headerImage02"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </FadeInWrapper>
      <div className="relative z-minus">
        <div className="bg-white">
          <div className="container px-6 pt-16 mx-auto pb- sm:px-8 md:px-12 md:py-8 lg:px-6 lg:py-12 xl:py-12 xl:px-16">
            <p className="mt-16 mb-6 text-sm font-medium leading-tight text-center text-gray-700 md:text-left md:hidden">
              Cosmetic brand partnerships:
            </p>
            <div className="md:hidden">
              <Carousel
                settingsOverrides={{
                  dots: false,
                  arrows: false,
                  infinite: true,
                  autoplay: true,
                  draggable: false,
                  autoplaySpeed: 1250,
                  waitForAnimate: true,
                  swipeToSlide: false,
                  speed: 300,
                  responsive: [
                    {
                      breakpoint: 768, //Tailwind md default
                      settings: {
                        slidesToShow: 2,
                      },
                    },
                  ],
                }}
              >
                <div className="flex items-center justify-center h-full">
                  <div className="flex">
                    {" "}
                    <img
                      className="w-48 px-5 py-5"
                      src={teoxane}
                      alt="Teoxane logo"
                    />
                  </div>
                </div>
                <div className="flex items-center justify-center h-full">
                  <div className="flex">
                    {" "}
                    <img
                      className="h-16 px-5 py-5"
                      src={profhilo}
                      alt="Profhilo logo"
                    />
                  </div>
                </div>
                <div className="flex items-center justify-center h-full">
                  <div className="flex">
                    {" "}
                    <img
                      className="w-48 px-5 py-5"
                      src={zo}
                      alt="Zo Skin Health logo"
                    />
                  </div>
                </div>
                <div className="flex items-center justify-center h-full">
                  <div className="flex">
                    {" "}
                    <img
                      className="w-48 px-3 py-3"
                      src={neostrata}
                      alt="Neostrata logo"
                    />
                  </div>
                </div>
                <div className="flex items-center justify-center h-full">
                  <div className="flex">
                    {" "}
                    <img
                      className="h-16 px-5 py-5"
                      src={mesoestetic}
                      alt="Mesoestetic logo"
                    />
                  </div>
                </div>
              </Carousel>
            </div>
            <div>
              <div className="hidden gap-2 md:grid lg:grid-cols-6">
                <p className="text-sm font-medium leading-relaxed text-center text-gray-700 md:items-center lg:flex lg:text-left">
                  Cosmetic brand partnerships:
                </p>
                <div className="flex items-center justify-center w-full h-16 mb-5 lg:mb-0">
                  {" "}
                  <img
                    className="h-full p-5 lg:p-4"
                    src={teoxane}
                    alt="Teoxane logo"
                  />
                </div>
                <div className="flex items-center justify-center h-16 mb-5 lg:mb-0">
                  {" "}
                  <img
                    className="h-full p-5 lg:p-4"
                    src={profhilo}
                    alt="Profhilo logo"
                  />
                </div>
                <div className="flex items-center justify-center w-full h-16 mb-5 lg:mb-0">
                  {" "}
                  <img
                    className="w-48 lg:p-4"
                    src={zo}
                    alt="Zo Skin Health logo"
                  />
                </div>
                <div className="flex items-center content-center justify-center w-full h-16 mb-5 lg:mb-0">
                  {" "}
                  <img
                    className="w-48 lg:p-2"
                    src={neostrata}
                    alt="Neostrata logo"
                  />
                </div>
                <div className="flex items-center justify-center w-full h-16 mb-5 lg:mb-0">
                  {" "}
                  <img
                    className="h-full p-4 lg:p-4"
                    src={mesoestetic}
                    alt="Mesoestetic logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FadeInWrapper>
        <div className="container px-6 py-16 mx-auto sm:px-8 md:px-12 lg:px-6 xl:pb-24 xl:pt-4 xl:px-12">
          <div className="xl:px-4">
            <div className="grid grid-cols-12 gap-4 lg:gap-6">
              <div className="col-span-12 grid-row-1 lg:col-span-8 xl:col-span-7">
                <h2 className="block mt-8 mb-8 leading-tight lg:mb-12">
                  Bespoke Cosmetic Injectables and Skin Treatment Plans.
                </h2>
              </div>
              <div className="flex flex-col col-span-6 row-start-2 lg:col-span-3">
                <div className="flex items-center justify-center w-8 h-8 mb-4 bg-gray-100 rounded-full">
                  <VscCheck size="16" className="inline text-black" />
                </div>
                <h2 className="font-sans text-base font-semibold leading-tight tracking-tight capitalize">
                  Reduce signs of ageing
                </h2>
                <p className="pt-2 text-gray-700">
                  Targeting the formation of fine lines and age related volume
                  loss
                </p>
              </div>
              <div className="flex flex-col col-span-6 row-start-2 lg:col-span-3">
                <div className="flex items-center justify-center w-8 h-8 mb-4 bg-gray-100 rounded-full">
                  <VscCheck size="16" className="inline text-black" />
                </div>
                <h2 className="col-span-6 font-sans text-base font-semibold leading-tight tracking-tight capitalize">
                  Define your facial features
                </h2>
                <p className="pt-2 text-gray-700 ">
                  Bringing out the features that complement your facial
                  proportions
                </p>
              </div>
              <div className="flex flex-col col-span-6 row-start-3 pt-12 lg:pt-0 lg:col-span-3 lg:row-start-2">
                <div className="flex items-center justify-center w-8 h-8 mb-4 bg-gray-100 rounded-full">
                  <VscCheck size="16" className="inline text-black" />
                </div>
                <h2 className="col-span-6 font-sans text-base font-semibold leading-tight tracking-tight capitalize">
                  Improve your skin health
                </h2>
                <p className="pt-2 text-gray-700 ">
                  Treating cosmetic skin concerns and improving overall skin
                  complexion
                </p>
              </div>
              <div className="flex flex-col col-span-6 row-start-3 pt-12 lg:pt-0 lg:col-span-3 lg:row-start-2">
                <div className="flex items-center justify-center w-8 h-8 mb-4 bg-gray-100 rounded-full">
                  <VscCheck size="16" className="inline text-black" />
                </div>
                <div>
                  <h2 className="font-sans text-base font-semibold leading-tight tracking-tight capitalize">
                    Confidence inside & out
                  </h2>
                  <p className="pt-2 text-gray-700">
                    Working together towards a skin that you feel beautifully
                    confident in
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FadeInWrapper>
      <FadeInWrapper>
        <div className="bg-gray-100">
          <div className="container px-6 mx-auto sm:px-8 md:px-12 lg:px-6 xl:px-16">
            <TextImage
              additionalClasses={helpers.layoutSevenFiveLarge}
              image={cover1}
              imageAlt="Dr Yalda gives a consultation to a client, and pushes hair back to reveal facial features"
            >
              <h2 className="mb-4 text-4xl leading-tight">
                Unsure of which treatment you want? No problem.
              </h2>
              <p>
                With the ever-growing beauty industry, the number of options can
                feel overwhelming. Our team are experts at identifying those
                tweaks and use an ethical approach to help you look and feel
                your very best. All treatments offered are non-invasive, with
                minimal downtime.
              </p>
              <Link
                className={`${buttons.btnSecondaryOutline}`}
                to="/treatments"
              >
                See Treatments
                <ArrowRight
                  size="16"
                  color="currentColor"
                  className="inline ml-2"
                />
              </Link>
            </TextImage>
          </div>
        </div>
      </FadeInWrapper>
      <FadeInWrapper>
        <TextImage
          additionalClasses={helpers.layoutSevenFiveLarge}
          image={yaldaNeedle}
          reverse
          imageAlt="Dr Yalda holds a bottle of product, measuring it with a medical syringe"
        >
          <h2 className="mb-4 text-4xl leading-tight">
            A fully qualified medical practitioner, every time!
          </h2>
          <p className="text-gray-700">
            Drawing upon our collective expertise and following an ethical,
            evidence-based approach, Dr Yalda trains each medical practitioner
            within her team to the highest standards. Her team consists of
            Doctors and Pharmacists. Dr Yalda and her team run clinics in
            Nottingham, Leeds and Manchester.
          </p>
          <Link className={`${buttons.btnSecondaryOutline} block`} to="/about">
            About Dr Yalda
            <ArrowRight
              size="16"
              color="currentColor"
              className="inline ml-2"
            />
          </Link>
        </TextImage>
      </FadeInWrapper>
      <FadeInWrapper>
        <div>
          <div className="container px-6 py-12 mx-auto border-t border-gray-200 border-solid sm:px-8 md:px-12 lg:px-0">
            <div className="grid grid-cols-12">
              <h2 className="col-span-12 mb-4 text-4xl leading-tight sm:col-span-10 sm:col-start-2 sm:text-center lg:text-center">
                It&lsquo;s the <i className="lowercase">results</i> that count.
              </h2>
              <p className="col-span-12 mb-16 text-gray-700 sm:col-span-10 sm:col-start-2 sm:text-center lg:text-center lg:col-start-3 lg:col-span-8 xl:col-start-4 xl:col-span-6">
                With our experience and artistic eye, in conjunction with using
                the most premium brands we provide patients with satisfiying,
                long lasting results.
              </p>
            </div>
            <div className="grid grid-cols-12 md:gap-8 lg:px-16">
              <div className="col-span-10 col-start-2 mb-12 md:col-span-6 lg:col-span-3">
                <ProductSlider leftImage={lipsAfter} rightImage={lipsBefore} />
                <p className={`${helpers.caption} mt-4`}>Lip Fillers</p>
              </div>
              <div className="col-span-10 col-start-2 mb-12 md:col-span-6 lg:col-span-3">
                <ProductSlider
                  leftImage={profhiloBefore}
                  rightImage={profhiloAfter}
                />
                <p className={`${helpers.caption} mt-4`}>Profhilo</p>
              </div>
              <div className="col-span-10 col-start-2 mb-12 md:col-span-6 lg:col-span-3">
                <ProductSlider
                  leftImage={tearTroughsBefore}
                  rightImage={tearTroughsAfter}
                />
                <p className={`${helpers.caption} mt-4`}>Tear Trough Fillers</p>
              </div>
              <div className="col-span-10 col-start-2 mb-12 md:col-span-6 lg:col-span-3">
                <ProductSlider
                  leftImage={jawlineBefore}
                  rightImage={jawlineAfter}
                />
                <p className={`${helpers.caption} mt-4`}>Jawline Contouring</p>
              </div>
            </div>
          </div>
        </div>
      </FadeInWrapper>
      <FadeInWrapper>
        <div className="px-6 py-16 bg-gray-100 sm:px-8 md:px-12 md:py-16 lg:py-24 lg:px-6 xl:pt-32 ">
          <div className="container grid grid-cols-12 gap-6 mx-auto xl:px-16">
            <div className="col-span-12 sm:col-span-10 sm:col-start-2 sm:text-center lg:col-span-8 lg:col-start-3 xl:col-span-4">
              <div className="xl:text-left">
                <h2 className="mb-4 leading-tight">
                  Why clients <i>love</i> their treatments.
                </h2>
                <p className="mb-8 text-gray-700">
                  We go above and beyond to ensure that our clients are
                  completely satisfied by their results. Our clients regard us
                  as a personable, results-oriented team.
                </p>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-10 lg:col-start-2 xl:col-span-8 xl:col-start-5">
              <div className="grid grid-cols-1 gap-6 mb-12 sm:grid-cols-2">
                {reviews.map(({ author, message, image }, i) => (
                  <TestimonialCard
                    classes={``}
                    key={`testimonial-${i}`}
                    name={author}
                    image={image}
                    message={message}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </FadeInWrapper>
      <FadeInWrapper>
        <div className="grid py-6 bg-gray-100 lg:grid-cols-4">
          <div className="container col-span-12 px-6 py-16 mx-auto sm:px-8 md:px-12 lg:px-6 xl:px-16">
            <h2 className="mb-12 leading-tight text-center">Latest posts</h2>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
              {blogsDestructured.map(
                (
                  {
                    title,
                    category,
                    path,
                    date,
                    excerpt,
                    image,
                    imageLarge,
                    readingTime,
                  },
                  i
                ) => (
                  <PostCard
                    key={`post-card-${i}`}
                    title={title}
                    category={category}
                    path={path}
                    date={date}
                    excerpt={excerpt}
                    image={image}
                    imageLarge={imageLarge}
                    readingTime={readingTime}
                  />
                )
              )}
            </div>
            <div className="lg:text-center">
              <Link
                className={`${buttons.btnSecondaryOutline} block mt-8 lg:inline-block`}
                to="/blog"
              >
                View all posts
              </Link>
            </div>
          </div>
        </div>
      </FadeInWrapper>
      <FadeInWrapper>
        <CTABlock />
      </FadeInWrapper>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allMarkdownRemark(
      limit: 4
      filter: { fileAbsolutePath: { regex: "/blogs/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            layout
            title
            id
            category
            author
            path
            date(formatString: "MMMM DD, YYYY")
            excerpt
            image
            imageLarge
          }
          fields {
            readingTime {
              text
            }
          }
        }
      }
    }
    allFile(filter: { sourceInstanceName: { eq: "reviews" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              author
              message
              image
            }
          }
        }
      }
    }
  }
`
