import React from "react"
import { Link } from "gatsby"
import { Calendar } from "react-feather"
import helpers from "../../styles/helpers.module.css"

type PostCardProps = {
  title: string
  category: string
  path: string
  date: string
  image: string
  readingTime: {
    text: string
  }
}

export const PostCard = ({
  title,
  category,
  path,
  date,
  image,
  readingTime,
}: PostCardProps) => {
  const readingTimeText = readingTime.text

  return (
    <div className="relative flex flex-col h-full bg-white shadow-sm">
      <div className="relative">
        <img width="100%" src={image} alt="Post" />
      </div>
      <div className="flex flex-col items-start flex-grow p-6 bg-white">
        <div className={`${helpers.chipDark} inline-block mb-4`}>
          {category}
        </div>
        <h3
          data-testid="postcardTitle"
          className="flex-grow pb-16 text-2xl leading-tight tracking-normal"
        >
          {title}
        </h3>
        <div className="flex flex-wrap items-center justify-between w-full mb-2">
          <div className="flex items-center">
            <Calendar size="16" className="inline mr-2 text-gray-600" />
            <p className={`${helpers.caption} mr-1`}>{`${date}`}</p>
          </div>
          <p className={`${helpers.caption}`}>{readingTimeText}</p>
        </div>
      </div>
      <Link className="absolute top-0 bottom-0 left-0 right-0" to={path}>
        <span className="sr-only">{title}</span>
      </Link>
    </div>
  )
}
